import {
  HOME_CARE_SOLUTIONS,
  MEDICAL_SUPPLIES_SOLUTIONS,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_REHABILITATION,
  SEARCH_TYPE_TRANSPORT,
} from "@recare/core/consts";
import { isMobilePatient } from "@recare/core/model/patients";
import { isPastDate } from "@recare/core/model/utils/dates";
import { convertAddressToLocation } from "@recare/core/model/utils/location";
import {
  Auction,
  AuctionNoPatient,
  Patient,
  QueryProgress,
  SearchType,
} from "@recare/core/types";
import { useCreateAuction } from "apollo/hooks/mutations";
import { getUnixTime } from "date-fns";
import { useCareseekerNavigationHandlers } from "dsl/hooks/useNavigationHandlers";
import { useCallback } from "react";

const getSolutionsForSearchType = (searchType: SearchType) => {
  switch (searchType) {
    case SEARCH_TYPE_HOME_CARE:
      return HOME_CARE_SOLUTIONS;
    case SEARCH_TYPE_MEDICAL_SUPPLIES:
      return MEDICAL_SUPPLIES_SOLUTIONS;
    default:
      return [];
  }
};

const getTransportDestination = (
  auction: Auction | AuctionNoPatient | null | undefined,
) => {
  const mobilePatient = isMobilePatient(auction?.solutions);
  const searchLocation = auction?.profile?.search_location;
  if (mobilePatient) return searchLocation;

  const auctionWinnerLocation = convertAddressToLocation(
    auction?.winner?.careprovider?.address,
  );
  return auctionWinnerLocation;
};

const createInitialAuction = ({
  auction,
  patient,
  searchType,
}: {
  auction: AuctionNoPatient;
  patient: Patient;
  searchType: SearchType;
}): Partial<Auction> => ({
  session_key_context: patient?.session_key_context,
  search_type: searchType,
  solutions: getSolutionsForSearchType(searchType),
  patient,
  start_date: isPastDate(auction?.start_date)
    ? getUnixTime(new Date())
    : auction?.start_date,
  profile: {
    ...(auction?.profile || {}),
    has_transitional_care: undefined,
  },
});

export const auctionMapping: {
  [index in SearchType]: ({
    auction,
    patient,
  }: {
    auction: AuctionNoPatient;
    patient: Patient;
  }) => Partial<Auction>;
} = {
  [SEARCH_TYPE_REHABILITATION]: ({
    auction,
    patient,
  }: {
    auction: AuctionNoPatient;
    patient: Patient;
  }) =>
    createInitialAuction({
      searchType: SEARCH_TYPE_REHABILITATION,
      auction,
      patient,
    }),
  [SEARCH_TYPE_HOSPITAL]: ({
    auction,
    patient,
  }: {
    auction: AuctionNoPatient;
    patient: Patient;
  }) =>
    createInitialAuction({
      searchType: SEARCH_TYPE_HOSPITAL,
      auction,
      patient,
    }),
  [SEARCH_TYPE_CARE]: ({
    auction,
    patient,
  }: {
    auction: AuctionNoPatient;
    patient: Patient;
  }) =>
    createInitialAuction({
      searchType: SEARCH_TYPE_CARE,
      auction,
      patient,
    }),
  [SEARCH_TYPE_MEDICAL_SUPPLIES]: ({
    auction,
    patient,
  }: {
    auction: AuctionNoPatient;
    patient: Patient;
  }) =>
    createInitialAuction({
      searchType: SEARCH_TYPE_MEDICAL_SUPPLIES,
      auction,
      patient,
    }),
  [SEARCH_TYPE_HOME_CARE]: ({
    auction,
    patient,
  }: {
    auction: AuctionNoPatient;
    patient: Patient;
  }) =>
    createInitialAuction({
      searchType: SEARCH_TYPE_HOME_CARE,
      auction,
      patient,
    }),
  [SEARCH_TYPE_TRANSPORT]: ({
    auction,
    patient,
  }: {
    auction: AuctionNoPatient;
    patient: Patient;
  }) =>
    createInitialAuction({
      searchType: SEARCH_TYPE_TRANSPORT,
      auction: {
        ...auction,
        profile: {
          search_destination: getTransportDestination(auction),
        },
      },
      patient: {
        ...(patient || {}),
        station:
          auction?.search_type === SEARCH_TYPE_HOSPITAL
            ? patient?.station
            : undefined,
      },
    }),
};

export const useCreateNewAuction = ({
  firstAuction,
  patient,
}: {
  firstAuction: Auction | AuctionNoPatient;
  patient: Patient;
}): [(searchType: SearchType) => void, QueryProgress] => {
  const [createAuction, queryProgress] = useCreateAuction(patient);
  const appNavigation = useCareseekerNavigationHandlers();

  const onCreate = useCallback(
    (searchType: SearchType) => {
      const createAuctionForSearchType =
        auctionMapping[searchType as SearchType];

      return createAuction(
        createAuctionForSearchType({
          auction: firstAuction,
          patient,
        }),
      ).then(({ data: createdAuctionResponse }) => {
        const createdAuction = createdAuctionResponse?.auction;

        if (!createdAuction?.id)
          return console.error(
            "error creating new auction",
            JSON.stringify(auctionMapping, null, 2),
          );

        appNavigation.patient.goToAssessing(patient.id, createdAuction.id);
      });
    },
    [createAuction, appNavigation, firstAuction, patient],
  );

  return [onCreate, queryProgress];
};
