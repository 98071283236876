import {
  CARESEEKER_TYPE_HOSPITAL,
  REHABILITATION_PAYMENT_OPTION,
  SEARCH_TYPE_CARE,
  SEARCH_TYPE_HOME_CARE,
  SEARCH_TYPE_HOSPITAL,
  SEARCH_TYPE_MEDICAL_SUPPLIES,
  SEARCH_TYPE_TRANSPORT,
  SOLUTION_HOME_CARE,
  SOLUTION_MEDICAL_SUPPLIES,
} from "@recare/core/consts";
import { descriptiveWhitelist, isCare } from "@recare/core/model/auctions";
import {
  showMultipleSpecializations,
  showSpecializations,
} from "@recare/core/model/patients";
import {
  convertBirthDateIn,
  convertBirthDateOut,
  isPastDate,
} from "@recare/core/model/utils/dates";
import {
  Careseeker,
  GetOntologiesType,
  GetOntologyType,
  SearchType,
} from "@recare/core/types";
import {
  ConversionModel,
  algoliaReactSelectDef,
  convertModelDefinition,
  encryptedDef,
  phoneNumberModel,
  valueDef,
} from "@recare/react-forms-state";
import { fromUnixTime, getUnixTime, isSameDay, isValid } from "date-fns";
import {
  convertOutPayers,
  validatePayersInsurance,
} from "dsl/ecosystems/PatientAssessment/SharedModelDefinitions";
import { blacklist, composeAnd } from "dsl/ecosystems/PatientAssessment/show";
import { Iterable, List, fromJS } from "immutable";

export function defaultSolutionsForSearchType(searchType: SearchType) {
  if (searchType == SEARCH_TYPE_MEDICAL_SUPPLIES)
    return [SOLUTION_MEDICAL_SUPPLIES];
  if (searchType == SEARCH_TYPE_HOME_CARE) return [SOLUTION_HOME_CARE];
  return null;
}

export function defaultSearchType(
  careseeker: Careseeker | undefined,
): SearchType | null | undefined {
  if (careseeker?.patient_types?.length == 1)
    return careseeker?.patient_types[0];

  return null;
}

const model: ConversionModel = {
  ...valueDef("id"),
  profile: {
    ...valueDef("search_location"),
  },
  ...valueDef("start_date_flexible", {
    show: descriptiveWhitelist([
      {
        country: "DE",
        search_type: SEARCH_TYPE_CARE,
      },
      {
        country: "DE",
        search_type: SEARCH_TYPE_HOSPITAL,
      },
    ]),
  }),
  ...valueDef("start_date", {
    fieldRequired: true,
    errorString: (t) => t.actions.missing,
    defaultValue: (props: AnyObject) => {
      const searchType = defaultSearchType(props.careseeker);
      if (searchType === SEARCH_TYPE_HOSPITAL) return getUnixTime(new Date());
      return null;
    },
    validate: (value, props) => {
      const { formInputValue, translations } = props;
      // date can't be empty
      if (!value) return false;

      // on initial creation of the patient, formInput is undefined, users can't enter date in past
      // on edit, formInput is filled, users can go to next step with same date, on change it can't be in the past either
      if (
        (!formInputValue && isPastDate(value, -1)) ||
        (formInputValue &&
          isPastDate(value, -1) &&
          !isSameDay(
            fromUnixTime(formInputValue.start_date),
            fromUnixTime(value),
          )) ||
        !isValid(value)
      )
        return {
          customMessage: translations.general.errorDateInPast,
        };

      return true;
    },
  }),
  ...valueDef("search_type", {
    fieldRequired: true,
    defaultValue: (props: AnyObject) => defaultSearchType(props.careseeker),
  }),
  ...valueDef("assessment_variant"),
  ...valueDef("solutions", {
    fieldRequired: true,
    errorString: (t) => t.actions.missing,
    defaultValue: (props: AnyObject) => {
      const searchType = defaultSearchType(props.careseeker);
      if (searchType) return defaultSolutionsForSearchType(searchType);
      return undefined;
    },
    convertOut: (value: List<number> | number) => {
      // Transport searches are limited to a single solution.
      // We therefore use a radio selector for the selection
      // which outputs a single solution.
      const solutions = List.isList(value)
        ? (value as List<number>)?.toJS()
        : value;
      return Array.isArray(solutions) ? solutions : [solutions];
    },
    convertIn: (
      value: List<number> | undefined,
      {
        formInputValue,
      }: {
        formInputValue: AnyObject | null;
      },
    ) => {
      if (!value) return null;
      const solutions = List.isList(value) ? value?.toJS() : value;

      const searchType = formInputValue?.search_type;
      // Also needed to handle the above mentioned transport case
      if (searchType === SEARCH_TYPE_TRANSPORT) {
        return solutions?.[0] ?? null;
      }
      return value;
    },
    validate: (value: List<number> | number | undefined) => {
      // Also needed to handle the above mentioned transport case
      const solutions = List.isList(value)
        ? (value as List<number>)?.toJS()
        : value;
      return Boolean(
        Array.isArray(solutions) ? (solutions as number[])?.length : solutions,
      );
    },
  }),
  ...valueDef("specializations", {
    convertIn: (
      value: any,
      props: {
        formInputValue: AnyObject | null;
        getOntologies: GetOntologiesType;
        getOntology: GetOntologyType;
      },
    ) => {
      const { formInputValue, getOntologies } = props;
      const inputValue = Iterable.isIterable(value) ? value.toJS() : value;
      const showMultiple = showMultipleSpecializations({
        searchType: formInputValue?.search_type,
      });

      if (
        !showSpecializations({ searchType: formInputValue?.search_type }) ||
        !inputValue?.length
      ) {
        return null;
      }
      const ontologyValues = getOntologies({
        type: "specializations",
        sort: true,
      });

      const specializations = ontologyValues
        .filter((ontVal) => inputValue.includes(ontVal.value))
        .map(({ name: label, value }) => ({ label, value }));

      return showMultiple ? specializations : specializations[0];
    },
    convertOut: (value: any) => {
      const outputValue = Iterable.isIterable(value) ? value.toJS() : value;
      if (outputValue) {
        const collection = Array.isArray(outputValue)
          ? outputValue
          : outputValue.value;
        if (Array.isArray(collection)) {
          return collection.map((selected) => selected.value);
        }
        return [collection];
      }
      return null;
    },
  }),
  ...valueDef("patient.id"),
  ...valueDef("patient.careseeker.id", {
    convertOut: (_, props) => {
      return props?.careseeker?.id;
    },
  }),
  payers: {
    ...valueDef("selected_payment_method", {
      convertOut: (value, _props, globalValue) => {
        const formValue = Iterable.isIterable(globalValue)
          ? globalValue.toJS()
          : globalValue;
        // if insurance is added to the patient creation then the selected payment option is decided there
        if (formValue?.payers_insurance?.id != null)
          return REHABILITATION_PAYMENT_OPTION.INSURANCE;
        return value;
      },
    }),
    ...algoliaReactSelectDef("insurance", {
      out_name: "payers_insurance",
      convertOut: convertOutPayers,
      validate: validatePayersInsurance,
    }),
  },
  patient: {
    ...valueDef("version"),
    ...valueDef("kis_import", { defaultValue: false }),
    ...valueDef("_kis_helper_element", {
      defaultValue: () => null,
      convertOut: () => undefined,
    }),
    ...valueDef("external_id", {
      show: composeAnd(
        blacklist("external_id"),
        descriptiveWhitelist([
          {
            country: "DE",
            sender_type: CARESEEKER_TYPE_HOSPITAL,
          },
        ]),
      ),
    }),
    ...valueDef("station_full_id", {
      convertIn: (input, _, immutableInput) => {
        const oldStation = immutableInput?.get("station");
        return input || { label: oldStation };
      },
      convertOut: (output) => {
        if (Number.isInteger(output)) return output;
        const outputValue = Iterable.isIterable(output)
          ? output.toJS()
          : output;
        return outputValue?.id ?? null;
      },
    }),
    social_worker: {
      complex: true,
      convertIn: (socialWorker, props) => {
        if (socialWorker == null) return props?.account?.id;
        return socialWorker.getIn(["id"], null);
      },
      convertOut: (output) => {
        const data = Iterable.isIterable(output) ? output.toJS() : output;
        if (!data) return {};
        return { id: data.value || data };
      },
      out: "social_worker_account_id",
      default: (props: any) =>
        props.account ? fromJS({ id: props.account.id }) : fromJS({}),
    },
    // Primarily KIS fields
    diagnosis: {
      ...valueDef("main_diagnosis"),
      ...valueDef("secondary_diagnosis"),
      ...valueDef("hl7_main_diagnoses"),
      ...valueDef("hl7_secondary_diagnoses"),
      ...valueDef("allergies"),
      ...valueDef("medical_history"),
      hospital_stay: {
        ...valueDef("operation_date"),
        ...valueDef("doctor_in_charge_in_hospital"),
        ...valueDef("doctor_in_charge_in_hospital_phone", {
          ...phoneNumberModel(false),
          defaultValue: (props: any) => {
            const stationInformation = props.careseeker?.stations_full;
            return stationInformation?.length === 1
              ? stationInformation[0].phone_number
              : "";
          },
        }),
      },
      dependency_diagnosis: {
        ...valueDef("barthel_index"),
      },
      ...valueDef("infection_and_germs"),
      ...valueDef("infection_and_germs_state"),
    },
    profile: {
      financing: {
        ...encryptedDef("insurance_number"),
        ...valueDef("insurance_id"),
        ...valueDef("insurance"),
        carelevel: {
          out: "carelevel",
          ...valueDef("level"),
        },
      },
      ...valueDef("gender"),
      ...encryptedDef("first_name"),
      ...encryptedDef("last_name"),
      ...encryptedDef("height"),
      ...encryptedDef("weight"),
      ...encryptedDef("birth_date", {
        convertIn: convertBirthDateIn,
        convertOut: convertBirthDateOut,
      }),
      communication: {
        ...valueDef("has_guardian"),
        ...encryptedDef("guardian_contact_information"),
        ...valueDef("relatives_available"),
        ...encryptedDef("relatives_description"),
        ...valueDef("patient_is_contact"),
        ...encryptedDef("patient_is_contact_description"),
      },
      general_practitioner: {
        ...valueDef("name", {
          out_name: "general_practitioner_name",
        }),
        ...valueDef("contact_details", {
          out_name: "general_practitioner_contact_details",
        }),
      },
      // end KIS only fields

      ...valueDef("care_duration_in_days", {
        errorString: (t) => t.actions.missing,
        validate: (value, props, globalProps) => {
          if (isCare(globalProps?.toJS().search_type)) {
            return !!value;
          }
          return true;
        },
      }),
      ...valueDef("interested_long_term_stay"),
      ...valueDef("direct_transfer_necessary"),
      ...valueDef("has_patient_consent", {
        show: descriptiveWhitelist([{ country: "FR" }]),
        validate: (value: boolean | null) => !!value,
      }),
    },
  },
};

export default convertModelDefinition(model);
