import { getName, OntologyType } from "@recare/core/model/utils/ontologies";
import { useGetOntologies } from "@recare/core/model/utils/ontologies/hooks";
import { RadioOption } from "@recare/core/types";
import { useHideComponent } from "@recare/react-forms-state";
import { useTranslations } from "@recare/translations";
import RadioGroup from "ds/components/RadioGroup";
import RadioGroupV2, { RadioGroupV2Props } from "ds/components/RadioGroupV2";
import { indexOf, sortBy } from "lodash";

type OntologyRadioGroupProps = {
  boldLabel?: boolean;
  elementName: string;
  excludeOptions?: (number | string)[];
  includeOptions?: (number | string)[];
  label?: string;
  margin?: string;
  nullOption?: string;
  sort?: (obj: RadioOption) => ReturnType<typeof indexOf>;
  type: OntologyType;
};

function useOptions({
  excludeOptions,
  includeOptions,
  nullOption,
  sort,
  type,
}: {
  excludeOptions?: (number | string)[];
  includeOptions?: (number | string)[];
  nullOption?: string;
  sort?: OntologyRadioGroupProps["sort"];
  type: OntologyType;
}) {
  const getOntologies = useGetOntologies();
  let options = getOntologies({ type }).map((ontology) => ({
    label: ontology.name,
    value: ontology.value,
    id: ontology.value as number | string,
  }));

  if (excludeOptions) {
    options = options.filter(
      (option: RadioOption) =>
        excludeOptions && !excludeOptions.includes(option.id),
    );
  }

  if (includeOptions) {
    options = options.filter(
      (option: RadioOption) => includeOptions?.includes(option.id),
    );
  }

  if (nullOption) {
    options.push({
      label: nullOption,
      value: -1,
      id: "default" as ToType,
    });
  }
  if (sort) {
    options = sortBy<RadioOption>(options, sort);
  }
  return options;
}

export function OntologyRadioGroupV2({
  elementName,
  excludeOptions,
  includeOptions,
  nullOption,
  sort,
  type,
  ...props
}: Omit<RadioGroupV2Props, "label" | "options"> &
  Pick<
    OntologyRadioGroupProps,
    "sort" | "type" | "includeOptions" | "excludeOptions" | "nullOption"
  >) {
  const translations = useTranslations();
  const hideComponent = useHideComponent(elementName);
  const options = useOptions({
    type,
    excludeOptions,
    includeOptions,
    nullOption,
    sort,
  });

  if (hideComponent) return null;

  return (
    <RadioGroupV2
      label={getName(type, translations)}
      options={options}
      elementName={elementName}
      {...props}
    />
  );
}

export default function OntologyRadioGroup({
  boldLabel,
  elementName,
  excludeOptions,
  includeOptions,
  label,
  margin,
  nullOption,
  sort,
  type,
}: OntologyRadioGroupProps) {
  const translations = useTranslations();
  const hideComponent = useHideComponent(elementName);
  const options = useOptions({
    type,
    excludeOptions,
    includeOptions,
    nullOption,
    sort,
  });

  if (hideComponent) return null;

  return (
    <RadioGroup
      marginWrapper={margin}
      elementName={elementName}
      label={label != null ? label : getName(type, translations)}
      options={options}
      bold={boldLabel}
    />
  );
}
